export const EXPLORER_URL = {
  369: "https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#/",
};
export const routes = [
  { tile: "MasterChef", url: "/masterchef" },
  { tile: "SNOWToken", url: "/snow" },
  { tile: "BILLToken", url: "/bill" },
  { tile: "SNOWNFT", url: "/nft" },
  // { tile: "ZAPPER", url: "/zap" },
];
