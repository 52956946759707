import React from "react";
import Footer from "./Footer";
import { routes } from "constants";

export default function Sidebar({ toggle, handleToggle }) {
  const currentUrl = window.location.pathname;
  return (
    <div
      className={` ${
        toggle ? "absolute z-[59] top-2 w-screen h-screen max-w-screen" : ""
      } items-center `}
    >
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        onClick={(e) => handleToggle(e)}
        className={`fixed transition-transform ${
          toggle
            ? "translate-x-[250px] w-screen h-screen max-w-screen bg-[#0c1221c7]"
            : "translate-x-0 top-2 p-2 mt-2  bg-[#0c1221c7]"
        } z-[9999] ms-3 sm:fixed text-sm text-gray-500 rounded-lg sm:hidden focus:outline-none dark:text-white`}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen  transition-transform -translate-x-full sm:translate-x-0  ${
          toggle ? "translate-x-0" : ""
        } `}
        aria-label="Sidebar"
      >
        <div
          className={`h-full px-3 py-4 overflow-y-auto ${
            toggle ? "bg-gray-900" : "bg-sidebar"
          }   rounded-md w-full`}
        >
          <ul className="space-y-4 font-medium sidebar_list">
            <li>
              <div className="">
                <a href="/" className="items-center gap-2 flex">
                  {/* <img src="/logo.png" className="h-16 w-18" alt="" /> */}
                  <span className="font-semibold text-3xl text-white ">
                    ADMIN PANEL
                  </span>
                </a>
              </div>
            </li>
            {routes.map((route, index) => (
              <li
                className={`sidebar_item ${
                  currentUrl === route.url ? "bg-[#122142] rounded-md" : ""
                }`}
                key={index}
              >
                <a
                  href={route.url}
                  className="flex items-center p-2  text-xl text-white rounded-lg dark:text-white hover:bg-[#122142] dark:hover:bg-gray-700 group"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="w-6 h-6  dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 12v1h4v-1m4 7H6a1 1 0 0 1-1-1V9h14v9a1 1 0 0 1-1 1ZM4 5h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                    />
                  </svg>

                  <span className="ms-3 ">{route.tile}</span>
                </a>
              </li>
            ))}
          </ul>
          <Footer />
        </div>
      </aside>
    </div>
  );
}
