const contractAddresses = {
  masterChef: "0x091eAb2260e3bd6c90757900161dBC32b8f57e40",
  zap: "0xd80FA08D95326bD29DEE5636206015d746e283cC", //"0x4ce860379A3558344678b602d2C9dB7228b7912a",
  nft: "0xA4f7893b2EB7fE13A7842d6Bc08ff9b01E6Ca3Fc",
  snow: "0x1373682091B9124E1123CBBc9336830600a20671",
  bill: "0xc0F1BA2780bBb41363d94859D8EBC26809dcC010",
  dai: "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
  wpls: "0xa1077a294dde1b09bb078844df40758a5d0f9a27",
  usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  usdcLp: "0x8F6DfB2Fa2f7Ccf9d7106E96207d8B947a89998a",
  snowWplslp: "0xb6F63EE536035002bBca533a5d3CbaA3638FE822",
  billWplslp: "0x1C2f109e263DF5575751A83e071Cd55C7f4Ac096",
  pls: "0x0000000000000000000000000000000000000000",
};

export default contractAddresses;
