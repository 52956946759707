import React from "react";
import { ToastContainer } from "react-toastify";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { pulsechain, pulsechainV4 } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { RefreshContextProvider } from "context/RefreshContext";

import { ALCHEMY_ID } from "config";

const Providers = ({ children }) => {
  const { chains, publicClient } = configureChains(
    [pulsechain, pulsechainV4],
    [alchemyProvider({ apiKey: ALCHEMY_ID }), publicProvider()]
  );

  const { connectors } = getDefaultWallets({
    appName: "SNOWBANK",
    projectId: "85ea32d265dfc865d0672c8b6b5c53d2",
    chains,
  });

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  const theme = extendTheme({
    styles: {
      global: () => ({
        body: {
          bg: "",
          color: "",
          input: "",
        },
      }),
    },
  });
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} theme={darkTheme()}>
        <ChakraProvider theme={theme}>
          <RefreshContextProvider>{children}</RefreshContextProvider>,
        </ChakraProvider>
        <ToastContainer />
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default Providers;
