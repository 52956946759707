import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

export default function Layout({ children }) {
  const [toggle, setToggle] = useState(false);
  const handleToggle = (e) => {
    e.preventDefault();
    setToggle(!toggle);
  };
  return (
    <div>
      <Header />
      <Sidebar toggle={toggle} handleToggle={handleToggle} />
      <div className="p-4 sm:ml-64">
        <div className="flex flex-col w-full items-center  min-h-[calc(100vh-90px)] relative p-2 bg-[#050812d9] text-white">
          {children}
        </div>
      </div>
    </div>
  );
}
