import React, { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

const NotFound = lazy(() => import("pages/Notfound"));
const PoolSettings = lazy(() => import("pages/PoolSettings"));
const SnowSetting = lazy(() => import("pages/SnowSetting"));
const BillSetting = lazy(() => import("pages/BillSetting"));
const NFTSetting = lazy(() => import("pages/NFTSetting"));
const ZAPSettings = lazy(() => import("pages/ZapSettings"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <PoolSettings />,
  },
  {
    path: "/masterchef",
    element: <PoolSettings />,
  },
  {
    path: "/snow",
    element: <SnowSetting />,
  },
  {
    path: "/bill",
    element: <BillSetting />,
  },
  {
    path: "/nft",
    element: <NFTSetting />,
  },
  {
    path: "/zap",
    element: <ZAPSettings />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
