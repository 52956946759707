import tokens from "./tokens";
import addresses from "constants/addresses";

export const MODE = "MAINNET";

export const ALCHEMY_ID = "86wTK178jC4XNO9sh-iVI7P5fV1tg_Dx";

export const CHAIN_ID_MAINNET = 369;
export const CHAIN_ID_TESTNET = 943;

export const NODES_MAINNET = ["https://rpc.pulsechain.com"];
export const NODES_TESTNET = ["https://rpc.v4.testnet.pulsechain.com"];

export const NODES = MODE === "TESTNET" ? NODES_TESTNET : NODES_MAINNET;

export const CHAIN_ID =
  MODE === "TESTNET" ? CHAIN_ID_TESTNET : CHAIN_ID_MAINNET;

export const NATIVE_COIN_SYMBOL = "PLS";

export const zapList = [
  {
    pid: 0,
    lpSymbol: tokens.pls.symbol,
    symbol: "PLS",
    isTokenOnly: true,
    isNative: true,
    lpAddresses: "0x0000000000000000000000000000000000000000",
    decimals: 18,
    logoA: tokens.pls.logo,
  },
  {
    pid: 0,
    lpSymbol: tokens.snow.symbol,
    symbol: "TEST2",
    isTokenOnly: true,
    isNative: false,
    lpAddresses: addresses.snow,
    decimals: 18,
    logoA: tokens.snow.logo,
  },
  {
    pid: 0,
    lpSymbol: tokens.wpls.symbol,
    symbol: "WPLS",
    isTokenOnly: true,
    isNative: false,
    lpAddresses: addresses.wpls,
    decimals: 18,
    logoA: tokens.wpls.logo,
  },
  {
    pid: 0,
    lpSymbol: tokens.dai.symbol,
    symbol: "DAI",
    isTokenOnly: true,
    isNative: false,
    lpAddresses: addresses.dai,
    decimals: 18,
    logoA: tokens.dai.logo,
  },
  {
    pid: 1,
    lpSymbol: tokens.bill.symbol,
    symbol: "TEST1",
    isTokenOnly: true,
    isNative: false,
    lpAddresses: addresses.bill,
    decimals: 18,
    logoA: tokens.bill.logo,
  },
  {
    pid: 1,
    lpSymbol: "SNOW-WPLS",
    symbol: "TEST2",
    isTokenOnly: false,
    isNative: false,
    lpAddresses: addresses.snowWplslp,
    decimals: 18,
    logoA: tokens.snow.logo,
    logoB: tokens.wpls.logo,
    tokenAAddress: tokens.snow.address,
    tokenBAddress: tokens.wpls.address,
  },
  {
    pid: 1,
    lpSymbol: "BILL-WPLS",
    symbol: "TEST1",
    isTokenOnly: false,
    isNative: false,
    lpAddresses: addresses.billWplslp,
    decimals: 18,
    logoA: tokens.snow.logo,
    logoB: tokens.wpls.logo,
    tokenAAddress: tokens.bill.address,
    tokenBAddress: tokens.wpls.address,
  },
];

export const farmsConfig = [
  {
    pid: 0,
    lpSymbol: "SNOW-wPLS",
    lpAddresses: addresses.snowWplslp,
    isTokenOnly: false,
    isNFTPool: false,
    token: tokens.snow,
    quoteToken: tokens.wpls,
    logoA: tokens.snow.logo,
    logoB: tokens.wpls.logo,
  },

  {
    pid: 1,
    lpSymbol: "BILL-wPLS",
    lpAddresses: addresses.billWplslp,
    isTokenOnly: false,
    isNFTPool: false,
    token: tokens.bill,
    quoteToken: tokens.wpls,
    logoA: tokens.bill.logo,
    logoB: tokens.wpls.logo,
  },
  {
    pid: 2,
    lpSymbol: "SNOW",
    lpAddresses: addresses.snow,
    isTokenOnly: true,
    isNFTPool: false,
    token: tokens.snow,
    quoteToken: tokens.snow,
    logoA: tokens.snow.logo,
    logoB: tokens.snow.logo,
  },
  {
    pid: 3,
    lpSymbol: "Snow Bank NFT",
    lpAddresses: addresses.nft,
    isTokenOnly: true,
    isNFTPool: true,
    token: tokens.nft,
    quoteToken: tokens.snow,
    logoA: "/assets/tokens/nft.png",
    logoB: "",
  },
];
