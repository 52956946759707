import addresses from "constants/addresses";

const tokens = {
  pls: {
    symbol: "PLS",
    address: addresses.wpls,
    decimals: 18,
    logo: "/assets/tokens/pls.png",
  },
  snow: {
    symbol: "SNOW",
    address: addresses.snow,
    decimals: 18,
    logo: "/assets/tokens/snow.png",
    projectLink: "https://snowbank.io/", // todo:
  },
  bill: {
    symbol: "BILL",
    address: addresses.bill,
    decimals: 18,
    logo: "/assets/tokens/bill.png",
    projectLink: "https://snowbank.io/", // todo:
  },
  wpls: {
    symbol: "WPLS",
    logo: "/assets/tokens/pls.svg",
    address: addresses.wpls,
    decimals: 18,
  },
  dai: {
    symbol: "DAI",
    address: addresses.dai,
    decimals: 18,
    logo: "/assets/tokens/dai.svg",
  },
};

export default tokens;
